<template>
    <div class="card h-full w-full p-0 shadow-3 overflow-y-hidden">
        <div style="background-color: #ffffff"
            class="card w-full h-5rem shadow-3 flex justify-content-center align-items-center">
            <h5 class="titulo text-color">CHAMADOS ANTERIORES</h5>
        </div>
        
        <div class="card h-7rem card-atendimento shadow-2 mt-8" v-for="chamado in atendimentos" :key="chamado.id">
            <div class="flex flex-row">
                <h3 class="m-0 font-light white-space-nowrap overflow-hidden text-overflow-ellipsis">
                    {{ chamado.name.toUpperCase() }}
                </h3>
            </div>
            <div class="flex h-3rem align-items-end">
                <div class="text-2xl white-space-nowrap overflow-hidden text-overflow-ellipsis">
                    {{ chamado.procedimentos }}
                </div>
                <h3 class="m-0 ml-auto font-light">{{ chamado.sala }}</h3>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        atendimentos: {
            type: Object
        }
    },
}
</script>
<style>
.titulo{
    font-size: 3.5vh;
    font-weight: 500;
    margin: 0px;
}
</style>