<template>
    <div class="header" />
    <div class="container">
        <div class="dimensao-tela">
            <div class="tela">
                <div class="fila-de-espera">
                    <FilaDeEspera :atendimentos="atendimemtosEmAberto" />
                </div>
                <div class="chamado-atual">
                    <div class="card shadow-3 h-full w-full">
                        <ChamadoAtual :loading="loading" :logoEmpresa="empresa.urlLogo" :atendimento="ultimoChamado" />
                    </div>
                </div>
                <div class="chamados-anteriores">
                    <ChamadosAnteriores :atendimentos="atendimemtosAnteriores" />
                </div>
            </div>
        </div>
    </div>
    <div class="footer" />
</template>
<script>
import BaseService from '@/services/BaseService';
import FilaDeEspera from './componentes/FilaDeEspera.vue';
import ChamadoAtual from './componentes/ChamadoAtual.vue';
import ChamadosAnteriores from './componentes/ChamadosAnteriores.vue';
import StatusAtendimento from '@/enums/StatusAtendimento';
import { getCompany } from '@/services/auth';

export default {
    components: {
        FilaDeEspera,
        ChamadoAtual,
        ChamadosAnteriores
    },
    data() {
        return {
            atendimentos: [],
            atendimemtosEmAberto: [],
            ultimoChamado: {},
            atendimemtosAnteriores: [],
            empresa: {},
            loading: false,
            rotaAtual: null
        };
    },
    async mounted() {
        this.loading = true;
        await this.load();
        this.empresa = await getCompany();
        this.rotaAtual = this.$route.name;
        this.loading = false;
    },
    methods: {
        async load() {
            await this.findAtendimentos();
            await this.findEmAberto();
            await this.findUltimoChamado();
            await this.findAnteriores();
            await this.autoLoad();
        },

        async autoLoad() {
            await this.findAtendimentos();
            await this.findEmAberto();
            await this.findUltimoChamado();
            await this.findAnteriores();
            setTimeout(async () => {
                if (this.rotaAtual == this.$route.name) {
                    await this.autoLoad();
                }
            }, 5000);
        },

        async findAtendimentos() {
            const service = new BaseService('/atendimento_check_in');
            const { data } = await service.findAll({});
            this.atendimentos = data;
        },
        async findEmAberto() {
            this.atendimemtosEmAberto = this.atendimentos.filter((p) => p.status == StatusAtendimento.EM_ABERTO);
        },
        async findUltimoChamado() {
            const ultimosChamados = this.atendimentos.filter((p) => p.status == StatusAtendimento.EM_ANDAMENTO);
            ultimosChamados.sort((a, b) => {
                let da = new Date(a.horarioChamada),
                    db = new Date(b.horarioChamada);
                return db - da;
            });
            this.ultimoChamado = ultimosChamados.shift();
        },
        async findAnteriores() {
            const atendimemtosAnteriores = this.atendimentos.filter(
                (p) => p.status == StatusAtendimento.EM_ANDAMENTO || p.status == StatusAtendimento.CONCLUIDO
            );
            const aa = atendimemtosAnteriores;
            aa.sort((a, b) => {
                let da = new Date(a.horarioChamada),
                    db = new Date(b.horarioChamada);
                return db - da;
            });
            atendimemtosAnteriores.shift();
            this.atendimemtosAnteriores = atendimemtosAnteriores;
        }
    }
};
</script>
<style scoped lang="scss">
.container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.dimensao-tela {
    height: 90vh;
    width: 98vw;
}

.header {
    background-color: #364de7;
    position: absolute;
    top: 0;
    left: 0;
    height: 3vh;
    width: 100vw;
}

.tela {
    height: 100%;
    display: grid;
    grid-template-areas: 'uc fde' 'ca fde';
    grid-auto-rows: 30% 68%;
    row-gap: 15px;
    column-gap: 15px;
    grid-template-columns: 70% 30%;
}

.fila-de-espera {
    grid-area: fde;
    margin-right: 10px;
}

:deep(.card) {
    border-radius: 15px;
}

.chamado-atual {
    grid-area: uc;
}

.chamados-anteriores {
    grid-area: ca;
}

.footer {
    background-color: #364de7;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3vh;
    width: 100vw;
}
</style>