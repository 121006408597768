<template>
    <div class="card h-full w-full p-0 shadow-3 overflow-y-hidden">
        <div style="background-color: #ffffff"
            class="card w-full shadow-3 h-5rem flex justify-content-center align-items-center">
            <h5 class="titulo text-color">FILA DE ESPERA</h5>
        </div>
        <div class="card h-8rem shadow-2 card-atendimento mt-8" v-for="atendimento in atendimentos" :key="atendimento.id">
            <div class="flex flex-row">
                <h3 class="m-0 font-light white-space-nowrap overflow-hidden text-overflow-ellipsis">
                    {{ atendimento.name }}
                </h3>
            </div>
            <div class="flex h-4rem align-items-end">
                <div class="text-2xl white-space-nowrap overflow-hidden text-overflow-ellipsis">
                    {{ atendimento.procedimentos }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        atendimentos: {
            type: Array
        }
    },
}
</script>
<style>
.card-atendimento {
    background-color: #f5f1f1;
    margin: 10px 10px 10px 10px !important;
}
.titulo{
    font-size: 4vh;
    font-weight: 500;
    margin: 0px;
    color: #ffffff;
}
</style>