<template>
    <div class="flex flex-row h-full w-full">
        <div v-if="logoEmpresa"
            class="container-logo flex h-full flex-column justify-content-center align-items-center">
            <img class="w-full" :src="logoEmpresa" />
            <ProgressSpinner v-if="loading" class="h-13rem" strokeWidth="3" />
        </div>
        <div v-if="loading" class="container-logo flex h-full flex-column justify-content-center align-items-center">
            <ProgressSpinner class="h-13rem" strokeWidth="3" />
        </div>
        <div class="flex flex-column w-full justify-content-center align-items-center">
            <div class="h-full flex flex-column justify-content-center align-items-center">
                <h1 class="nome-chamado text-center">{{ atendimento?.name ? atendimento?.name.toUpperCase()
            : 'Nenhum Paciente Chamado'
                    }}
                </h1>
                <h1 class="m-0 p-0 sala-chamado text-center">{{ atendimento?.sala ? atendimento?.sala.toUpperCase() :
            ''
                    }}</h1>
            </div>
        </div>
    </div>
</template>
<script>
import chamadoSong from '@/assets/audio/chamadaDuon2.wav'

export default {
    props: {
        atendimento: {
            type: Object
        },
        logoEmpresa: {
            type: String
        },
        loading: {
            type: Boolean
        }
    },
    watch: {
        atendimento: {
            handler(newValue, oldValue) {
                if (!newValue) return;
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.emitirSom(chamadoSong);
                    return
                }
            },
            deep: true
        },
    },
    methods: {
        emitirSom(som) {
            if (som) {
                let audio = new Audio(som);
                audio.play();
            }
        },
    },
}
</script>
<style>
.card-atendimento {
    background-color: #EDECEC;
}

.container-logo {
    height: 13rem;
    width: 13rem;
}

.nome-chamado {
    font-size: 4rem;
    font-weight: 500;
}

.sala-chamado {
    font-size: 3rem;
    font-weight: 500;
}
</style>